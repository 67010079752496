var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { queries, useCacheQuery } from '../index';
import { AccountStructure, getList, searchTree } from './utils';
import { assocPath, dissocPath, path } from 'ramda';
const initContext = {
    rootAccountsSortedByName: [],
    rootAccounts: [],
    getExpandedList: () => [],
    sortAccountsBy: 'name',
    setSortAccountsBy: () => { },
    toggleSortAccountsBy: () => { },
};
const AccountsContext = createContext(initContext);
export const AccountsProvider = ({ reportId, children }) => {
    const [sortAccountsBy, setSortAccountsBy] = useState('name');
    const accs = useCacheQuery(queries
        .relationshipOf('reportdefinition', reportId, 'accounts')
        // important for nodePath to be correct (first process parents)
        .sortByAttribute('level', 'ascending'));
    const rootAccounts = useMemo(() => {
        const accountMap = new Map();
        const roots = [];
        accs.forEach(account => {
            accountMap.set(account.id, new AccountStructure(account));
        });
        accs.forEach((account) => {
            const acc = accountMap.get(account.id);
            if (!acc)
                return;
            if (acc.account.relationships.parent.data === null || !accountMap.has(acc.account.relationships.parent.data.id)) {
                roots.push(acc);
                acc.nodePath = [roots.length - 1];
            }
            else {
                const parent = accountMap.get(acc.account.relationships.parent.data.id);
                if (parent) {
                    parent.children.push(acc);
                    acc.nodePath = [...parent.nodePath, parent.children.length - 1];
                }
            }
        });
        return roots;
    }, [accs]);
    const rootAccountsSortedByName = useMemo(() => [...rootAccounts].sort((a, b) => {
        const accountA = a.account.attributes;
        const accountB = b.account.attributes;
        return accountA.name.localeCompare(accountB.name);
    }), [rootAccounts]);
    const rootAccountsSortedByKey = useMemo(() => [...rootAccounts].sort((a, b) => {
        const accountA = a.account.attributes;
        const accountB = b.account.attributes;
        return accountA.level_keys[accountA.level - 1].key.localeCompare(accountB.level_keys[accountA.level - 1].key);
    }), [rootAccounts]);
    const sortedRootAccounts = sortAccountsBy === 'name'
        ? rootAccountsSortedByName
        : rootAccountsSortedByKey;
    const getExpandedList = useCallback((expanded = {}, isSearch = false) => {
        return getList(sortedRootAccounts, expanded, isSearch, sortAccountsBy);
    }, [sortedRootAccounts, sortAccountsBy]);
    const toggleSortAccountsBy = useCallback(() => {
        setSortAccountsBy((prev) => (prev === 'name' ? 'key' : 'name'));
    }, []);
    return (_jsx(AccountsContext.Provider, Object.assign({ value: useMemo(() => {
            const sortedRootAccounts = sortAccountsBy === 'name'
                ? rootAccountsSortedByName
                : rootAccountsSortedByKey;
            return {
                rootAccountsSortedByName,
                rootAccounts: sortedRootAccounts,
                getExpandedList,
                sortAccountsBy,
                toggleSortAccountsBy,
                setSortAccountsBy
            };
        }, [rootAccounts, getExpandedList, sortAccountsBy, toggleSortAccountsBy, rootAccountsSortedByName, rootAccountsSortedByKey]) }, { children: children })));
};
export function useReportAccounts(initialState = {}) {
    const [state, setState] = useState(initialState);
    const [searchTerm, updateSearchTerm] = useState('');
    const _a = useContext(AccountsContext), { rootAccounts, getExpandedList, sortAccountsBy } = _a, rest = __rest(_a, ["rootAccounts", "getExpandedList", "sortAccountsBy"]);
    const list = useMemo(() => {
        return getExpandedList(state, searchTerm.length > 0);
    }, [state, getExpandedList, searchTerm]);
    return Object.assign({ list, isExpanded: (nodePath) => !!path(nodePath, state), updateExpandedNode: (nodePath) => {
            const expanded = !path(nodePath, state);
            setState(expanded
                ? assocPath(nodePath, expanded, state)
                : dissocPath(nodePath, state));
        }, searchTerm, updateSearchTerm: (value) => {
            updateSearchTerm(value);
            const data = value.startsWith(searchTerm) ? list : rootAccounts;
            setState(searchTree(data, sortAccountsBy, value, {}));
        }, sortAccountsBy,
        rootAccounts, minAccountLevel: rootAccounts.length > 0
            ? rootAccounts[0].account.attributes.level
            : 1 }, rest);
}
