import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { divide } from '@exivity/fp'

import { createSelectors } from '../../store/utils'

export type UserMessage = {
  type: 'danger' | 'success'
  text: string
} | null

export type WorkChannel = 'foreground' | 'background' | 'runReports'

const initialState = {
  foregroundThreads: 0,
  foregroundProgressTracker: 0,
  backgroundThreads: 0,
  backgroundProgressTracker: 0,
  runReportThreads: 0,
  message: null as UserMessage
}

const getChannelProgress = (total: number, current: number) =>
  total === 0 ? 100 : 100 - divide(current, total) * 100

export const { actions, reducer } = createSlice({
  name: 'work',
  initialState,
  reducers: {
    addThread(state, action: PayloadAction<WorkChannel>) {
      if (action.payload === 'foreground') {
        state.foregroundThreads++
        state.foregroundProgressTracker++
      } else if (action.payload === 'runReports') {
        state.runReportThreads++
        state.foregroundProgressTracker++
      } else {
        state.backgroundThreads++
        state.backgroundProgressTracker++
      }
    },
    removeThread(state, action) {
      if (action.payload === 'foreground') {
        state.foregroundThreads--
        state.foregroundProgressTracker =
        (state.foregroundThreads + state.runReportThreads) === 0 ? 0 : state.foregroundProgressTracker
      } else if (action.payload === 'runReports') {
        state.runReportThreads--
        state.foregroundProgressTracker =
        (state.foregroundThreads + state.runReportThreads) === 0 ? 0 : state.foregroundProgressTracker
      } else {
        state.backgroundThreads--
        state.backgroundProgressTracker =
          state.backgroundThreads === 0 ? 0 : state.backgroundProgressTracker
      }
    },
    addMessage(state, action: PayloadAction<UserMessage>) {
      state.message = action.payload
    },
    removeMessage(state) {
      state.message = null
    }
  }
})

export const workReducer = reducer

export const workActions = actions

export const workSelectors = createSelectors((state) => state.work, {
  isRunReportBusy: (work) => work.runReportThreads > 0,
  isForegroundBusy: (work) => work.foregroundThreads > 0,
  isBackgroundBusy: (work) => work.backgroundThreads > 0,
  isAnyChannelBusy: (work) =>
    work.foregroundThreads + work.backgroundThreads + work.foregroundThreads > 0,
  getForegroundProgress: (work) =>
    getChannelProgress(work.foregroundProgressTracker, work.foregroundThreads + work.runReportThreads),
  getBackgroundProgress: (work) =>
    getChannelProgress(work.backgroundProgressTracker, work.backgroundThreads),
  getForegroundJobsCount: (work) => work.foregroundProgressTracker,
  getBackgroundJobsCount: (work) => work.backgroundProgressTracker,
  getUserMessage: (work) => work.message
})
