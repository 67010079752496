import { HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'
import store from '../../store'
import { WorkChannel, workActions } from '../../domains/work/state'
import { workThunks } from '../../domains/work/thunks'

export function handleWorkerThreads<T extends HookType>(
  channel: WorkChannel = 'background'
) {
  return (ctx: HookContext<T>) => {
    if (ctx.type === HookType.Before) {
      store.dispatch(workActions.addThread(channel))
    }

    if (ctx.type === HookType.After) {
      store.dispatch(workActions.removeThread(channel))
      // @ts-ignore
      const message = ctx.response?.message

      if (ctx.params.isResponseJson && message && ctx.options.flashMessage) {
        store.dispatch(workThunks.showSuccessMessage(message))
      }
    }

    if (ctx.type === HookType.Error) {
      store.dispatch(workActions.removeThread(channel))
    }

    return ctx
  }
}
